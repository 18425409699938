import "$styles/index.scss";
import "$styles/syntax-highlighting.css";

import "bootstrap";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

// import "bootstrap/js/dist/collapse";
// import "bootstrap/js/dist/modal";

function setYear() {
  let currentYear = document.querySelector("#current-year");
  currentYear.innerHTML = new Date().getFullYear().toString();
}

function smoothScrollToAnchors() {
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });
}

function bootstrapValidation() {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll(".needs-validation");

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms).forEach((form) => {
    form.addEventListener(
      "submit",
      (event) => {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add("was-validated");
      },
      false
    );
  });
}

function contactForm() {
  console.log("contactForm");
  const form = document.querySelector("#contact-form");
  if (!form) return;

  const formAction = form.getAttribute("action");
  const formMethod = form.getAttribute("method");

  // Dummy form inputs
  form.querySelector("#name").value = "John Doe";
  form.querySelector("#email").value = "darren@qldaerial.com.au";
  form.querySelector("#subject").value = "Test email " + Math.random();
  form.querySelector("#message").value = "Hello World! " + Math.random();

  form.addEventListener("submit", (e) => {
    e.preventDefault();

    if (!form.checkValidity()) {
      e.stopPropagation();
      form.classList.add("was-validated");
      return;
    }

    const formData = new FormData(form);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      subject: formData.get("subject"),
      message: formData.get("message"),
    };

    const alertSuccess = form.querySelector(".alert-success");
    const alertDanger = form.querySelector(".alert-danger");
    alertSuccess.classList.add("invisible");
    alertDanger.classList.add("d-none");

    fetch(formAction, {
      method: formMethod,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        alertSuccess.classList.remove("invisible");
        form.classList.remove("was-validated");
        form.reset();
      })
      .catch((error) => {
        console.log("Error:", error);
        alertDanger.classList.remove("d-none");
      });
  });
}

function init() {
  // Basic options for a simple Google Map
  // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
  var mapOptions = {
    // How zoomed in you want the map to start at (always required)
    zoom: 11,

    // The latitude and longitude to center the map (always required)
    center: new google.maps.LatLng(40.67, -73.94), // New York

    // How you would like to style the map.
    // This is where you would paste any style found on Snazzy Maps.
    styles: [
      {
        featureType: "landscape.man_made",
        elementType: "geometry",
        stylers: [{ color: "#f7f1df" }],
      },
      {
        featureType: "landscape.natural",
        elementType: "geometry",
        stylers: [{ color: "#d0e3b4" }],
      },
      {
        featureType: "landscape.natural.terrain",
        elementType: "geometry",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.business",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.medical",
        elementType: "geometry",
        stylers: [{ color: "#fbd3da" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#bde6ab" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffe15f" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#efd151" }],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.local",
        elementType: "geometry.fill",
        stylers: [{ color: "black" }],
      },
      {
        featureType: "transit.station.airport",
        elementType: "geometry.fill",
        stylers: [{ color: "#cfb2db" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#a2daf2" }],
      },
    ],
  };

  // Get the HTML DOM element that will contain your map
  // We are using a div with id="map" seen below in the <body>
  var mapElement = document.getElementById("contact-map");

  // Create the Google Map using our element and options defined above
  var map = new google.maps.Map(mapElement, mapOptions);

  // Let's also add a marker while we're at it
  var marker = new google.maps.Marker({
    position: new google.maps.LatLng(40.67, -73.94),
    map: map,
    title: "Snazzy!",
  });
}

function playPauseVideo() {
  const videos = document.querySelectorAll("video");
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting && !entry.target.paused) {
          entry.target.pause();
        }
        if (entry.isIntersecting && entry.target.paused) {
          entry.target.play();
        }
      });
    },
    {
      threshold: 0,
    }
  );
  for (const video of videos) observer.observe(video);
}

document.addEventListener("DOMContentLoaded", () => {
  setYear();
  // smoothScrollToAnchors();
  // bootstrapValidation();
  // contactForm();
  // init();
  // playPauseVideo();

  let scrolled = false;
  document.addEventListener("scroll", (event) => {
    if (scrolled) {
      return;
    }
    scrolled = true;
    playPauseVideo();
    // setTimeout(() => {
    //   scrolled = false;
    // }, 1000);
  });
});
